/* Ports.css */

/* Color scheme */
:root {
  --primary-bg-color: #65dcf7; /* Solid background color */
  --window-color: #caf7f9; /* Darker background */
  --accent-color: #5d5dff; /* Yellow accent */
  --text-color: #000000; /* Bright text */
  --text-hover-color:#cccccc;
  --button-color: #4affae; /* Button color */
  --shadow-color: rgba(0, 0, 0, 0.4); /* Shadow color */
  --text-shadow-color:rgba(0, 0, 0, 0.503);
}

body {
  background-color: var(--primary-bg-color);
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
}

.ports-container {
  background-color: var(--window-color);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 20px var(--shadow-color);
  margin: 20px auto;
  max-width: 900px;
  position: relative;
  overflow: hidden;
  text-align: center;
  text-shadow: 1px 1px 1px var(--text-shadow-color);
}

.ports-container:before,
.ports-container:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 100%;
  background: linear-gradient(to right, transparent, var(--accent-color), transparent);
  top: 0;
  z-index: -1;
  animation: slide 2s infinite linear;
}

.ports-container:before {
  left: -50px;
}

.ports-container:after {
  right: -50px;
}

@keyframes slide {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(50px);
  }
}

.ports-navigation {
  margin-bottom: 30px;
  text-align: center;
}

.ports-navigation a {
  text-decoration: none;
  color: var(--text-color);
  padding: 12px 24px;
  border-radius: 30px;
  background-color: var(--button-color);
  border: 2px solid var(--accent-color);
  margin: 0 10px;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease-in-out, background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.ports-navigation a:hover {
  background-color: var(--accent-color);
  color: var(--text-hover-color);
  border-color: transparent;
  transform: translateY(-3px);
}

.ports-content {
  margin-top: 20px;
}

.ports-footer {
  margin-top: 40px;
  color: var(--text-color);
  text-align: center;
}

.image-container {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Distribute space between items */
  margin-top: 20px; /* Add margin above the image container */
}


.barcelona-image {
  width: 48%; /* Adjust width to leave space for margin */
  border-radius: 10px;
  height: auto;
}

.barcelona-content{
  font-size: 1.5em;
  font-weight: 500;
}


h1{
  font-size: 2em;
  font-weight: 600;
}

.section-title {
  font-weight: bold; /* Make section titles bold */
  font-size: 1em;  /* Slightly larger font size */
  margin: 20px 0 10px; /* Spacing around section titles */
}

.attraction-title {
  font-weight: 600; /* Semi-bold for attraction titles */
  font-size: .95em;   /* Standard font size for titles */
  margin: 5px 0;    /* Spacing around attraction titles */
}

.text-link {
  font-size: 0.9em; /* Make the font size smaller */
  line-height: .5; /* Reduce line height to remove extra space */
}

