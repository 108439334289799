/* ChatComponent.css */

.chat-component {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #f7f7f7;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chat-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.chat-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.chat-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

.chat-submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.chat-submit-button:hover {
    background-color: #0056b3;
}

.chat-error {
    color: red;
    font-size: 14px;
    text-align: center;
}

.chat-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.chat-message {
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    line-height: 1.5;
}

.chat-timestamp {
    display: block;
    font-size: 12px;
    color: #777;
    margin-top: 5px;
}

.emoji-button{
    width: 50px; /* Adjust size */
    height: 50px; /* Adjust size */
    font-size: 30px; /* Adjust emoji size */
    text-align: center; /* Center emoji */
    line-height: 50px; /* Center vertically */
    border: none; /* Remove default border */
    background-color: transparent; /* No background */
    cursor: pointer; /* Pointer cursor on hover */
    outline: none; /* Remove focus outline */
}

.form-group.chat-input-group {
    display: flex; /* Align children horizontally */
    align-items: center; /* Center items vertically */
    flex-direction: row;
  }
  
  .chat-input {
    flex: 1; /* Allow input to take available space */
    margin-right: 10px; /* Add space between input and button */
  }
  
  .emoji-button {
    font-size: 24px; /* Adjust emoji size */
    background: transparent; /* No background */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor */
  }

  .reactions-container {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.reaction {
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #f0f0f0;
}

.reaction:hover {
    background-color: #e0e0e0;
}

.add-reaction {
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 0.9em;
}

.add-reaction:hover {
    background-color: #e0e0e0;
}

  
