:root {
  --primary-bg-color: #65dcf7; /* Solid background color */
  --window-color: #caf7f9; /* Darker background */
  --accent-color: #5d5dff; /* Yellow accent */
  --text-color: #000000; /* Bright text */
  --text-hover-color:#cccccc;
  --button-color: #4affae; /* Button color */
  --shadow-color: rgba(0, 0, 0, 0.4); /* Shadow color */
  --text-shadow-color:rgba(0, 0, 0, 0.503);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: var(--primary-bg-color);
  color: var(--text-color);
  height: 100vh;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}


.home {
  text-align: center;
  padding: 20px;
}

.home-header h1 {
  font-size: 2.5em;
  margin: 20px 0;
  color: var(--accent-color);
  text-shadow: 2px 2px var(--text-color);
}

.home-content {
  font-size: 1.5em;
  font-weight: 1000;
  margin: 20px auto;
  color: var(--text-color);
  background-color: var(--window-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 70%;
}

.home-image {
  width: 300px;
  height: auto;
  border: 4px solid var(--text-color);
  border-radius: 10%;
  transition: transform 0.2s, border-color 0.2s;
}

.home-image:hover {
  transform: scale(1.1);
}

.home-navigation {
  margin-top: 20px; /* Added margin to separate navigation from content */
}

.home-navigation a {
  text-decoration: none;
  padding: 10px 20px; /* Adjust padding for better touch targets */
  background-color: var(--button-color); /* Sets background color */
  color: var(--text-color); /* Palm Green */
  font-size: 1.2em;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, text-shadow 0.3s;
}

.home-navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.home-navigation ul li {
  margin: 0 15px;
}

.home-navigation ul li a {
  text-decoration: none;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 30px;
  transition: background-color 0.3s, color 0.3s, text-shadow 0.3s;
}

.home-navigation ul li a:hover {
  background-color: var(--accent-color);
  color: var(--text-hover-color);
  text-shadow: 0px 0px 5px var(--accent-color);
}


.image{
  width: 30%;
  height: 30%;
  border: 2px var(--complementary-color); /* Add border */
  border-radius: 10px; /* Add rounded corners */
  display: block; /* Ensure image is displayed as block element */
  margin: 0 auto; /* Center image horizontally */
  object-fit: cover; /* Maintain aspect ratio and cover container */
}
