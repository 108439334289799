/* PasswordProtect.css */

.password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, rgb(12, 25, 107), rgb(83, 51, 230));
    font-family: 'Arial', sans-serif;
}

.password-box {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.password-box h2 {
    margin-bottom: 20px;
    color: #333;
}

.password-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    box-sizing: border-box;
}

.password-submit-button {
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.password-submit-button:hover {
    background: #0056b3;
}

.password-error {
    margin-top: 10px;
    color: red;
}
