.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  position: relative;
  z-index: -2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
}

.App-link {
  color: #61dafb;
}
.header {
  text-align: center;
  padding: 40px;
  background-color: #282c34;
  color: white;
  position: relative;
  overflow: hidden; /* Ensure animations stay inside this container */
}

.header::before {
  content: "";
  position: absolute;
  top: -50px;
  left: -50px;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, #09bced, transparent);
  animation: partyAnimation 3s linear infinite;
}

.header::after {
  content: "";
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, #ebb11e, transparent);
  animation: partyAnimation 3s linear infinite reverse;
}

@keyframes partyAnimation {
  0% {
    border-radius: 0%;
    transform: scale(1) rotate(0deg);
  }
  50% {
    border-radius: 50%;
    transform: scale(2) rotate(180deg);
    background-color: rgb(0, 213, 0);
  }
  100% {
    border-radius: 0%;
    transform: scale(1) rotate(360deg);
  }
}

.countdown-container {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.countdown-text {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ddeb1e;
  text-shadow: 2px 2px 8px #000;

}

.countdown-item {
  display: inline-block;
  position: relative;
}

.animate-spin {
  animation: spin .97s linear ;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg) scale(1);
  }
  50% {
    transform: rotateZ(180deg) scale(.5);
  }
  100% {
    transform: rotateZ(360deg) scale(0);
   }
}


.animated {
  display: inline-block;
  transition: transform .8s ease;
}


.countdown {
  font-size: 3rem;
  color: #00ced1;
  text-shadow: 2px 2px 8px #000;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.countdown-item {
  display: inline-block;
  transition: transform 0.5s;
}

.countdown-item.spin {
  animation: spin 0.5s;
}



.navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  z-index: 1;
}

.navigation ul li {
  display: inline;
}

.navigation ul li a {
  background-color: #17191d;
  color: #9900ff;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.navigation ul li a:hover {
  background-color: #9900ff;
  color: white;
}

.navigation ul li a:active {
  background-color: #a723ff;
}

  


.home{
  flex-grow: 1;
  background-color: #61dafb;
  height: 100vh;
  text-align: center;
}

.ship-container {
  background-color: #282c34; /* Dark background */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  margin: 20px auto;
  max-width: 600px;
  position: relative;
  overflow: hidden;
}

.ship-container:before, .ship-container:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 100%;
  background: linear-gradient(to right, transparent, #34b40d, transparent); /* Gradient animation */
  top: 0;
  z-index: -1;
  animation: slide 2s infinite linear;
}

.ship-container:before {
  left: -50px;
}

.ship-container:after {
  right: -50px;
}

@keyframes slide {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(50px);
  }
}



nav {
  margin-bottom: 30px;
  text-align: center;
}

nav a {
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 30px;
  background-color: transparent;
  margin: 0 10px;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Shadow effect */
  transition: transform 0.2s ease-in-out, background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

nav a:hover {
  background-color: #ff69b4; /* Pink background on hover */
  color: white; /* White text on hover */
  border-color: transparent; /* Remove border on hover */
  transform: translateY(-3px); /* Lift up on hover */
}

.countdown-container {
  margin-bottom: 30px;
  padding: 0 10px; /* Add some padding for better spacing */
}

.countdown-text {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ddeb1e;
  text-shadow: 2px 2px 8px #000;
  /* Add responsive font size */
  font-size: calc(1.5rem + 1vw); /* Adjusts size based on viewport width */
}

.countdown {
  font-size: 3rem;
  color: #00ced1;
  text-shadow: 2px 2px 8px #000;
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .countdown-text {
    font-size: 1.5rem; /* Reduce font size for smaller screens */
  }

  .countdown {
    font-size: 2rem; /* Adjust countdown font size for smaller screens */
    flex-direction: column; /* Stack countdown items vertically */
  }

  .countdown-item {
    font-size: 1.2rem; /* Make individual countdown items smaller */
  }


}

